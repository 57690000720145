import UserLandingPage from 'content/pages/UserLandingPage';
import type { GetServerSideProps, GetServerSidePropsResult } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import redirectIfAuthenticated from 'utils/auth/redirectIfAuthenticated';
import getIsSsrMobile from 'utils/helpers/getIsSsrMobile';

import { wrapper } from 'redux-store';
import getProfileThunk from 'redux-store/thunks/profile/getProfileThunk';

export default UserLandingPage;

export const getServerSideProps: GetServerSideProps =
  wrapper.getServerSideProps(
    store =>
      async (context): Promise<GetServerSidePropsResult<object>> => {
        const shouldRedirect = redirectIfAuthenticated(context);
        if (shouldRedirect) return shouldRedirect;

        await store.dispatch(getProfileThunk({ context }));

        return {
          props: {
            ...(await serverSideTranslations(context.locale as string, [
              'common',
            ])),
            isRegistration: false,
            isSsrMobile: getIsSsrMobile(context),
          },
        };
      },
  );
